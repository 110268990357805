import axios from 'axios'
import { baseUrl } from './env'

// 超时时间（超时时间）
const TIME_OUT = 30000

class Axios {
	constructor() {
		this.init()
	}
	getUrl() {
		return baseUrl
	}
	// 初始化
	init() {
		// 全局的 axios 默认值
		axios.defaults.baseURL = baseUrl
		axios.defaults.timeout = TIME_OUT
		// 请求拦截器
		axios.interceptors.request.use(config => {
			if (!config.url) {
				console.log('请求地址出错', this, config)
			}
			return config
		}, err => {
			return Promise.reject(err)
		})
		axios.interceptors.response.use(res => {
			return res
		}, error => {
			return Promise.reject(error)
		})
	}
	// 是否是用户模块请求
	isUserModel(url) {
		axios.defaults.baseURL = baseUrl
	}
	/**
	 * GET 请求 {es6解构赋值}
	 * @param type 包含url信息
	 * @param data 需要发送的参数
	 * @returns {Promise}
	 * @constructor
	 */
	get(url, urlData, data = 0) {
		this.isUserModel(url)
		this.promise = new Promise((resolve, reject) => {
			if (data === 0) {
				url += '?'
				for (const item in urlData) {

					url += item + '=' + urlData[item] + '&'
				}
			} else if (data === 1) {
				for (const item in urlData) {
					url += '/' + urlData[item]

				}
			}

			axios.get(url).then((res) => {
				resolve(res.data)
			}).catch((err) => {
				reject(err)
			})
		})
		return this.promise
	}

	/**
	 * POST 请求
	 * @param type Object 包含url信息
	 * @param data Object 需要发送的参数
	 * @param urlData Object 需要拼接到地址栏的参数
	 * @returns {Promise}
	 * @constructor
	 */
	post(url, Data) {
		this.isUserModel(url)
		this.promise = new Promise((resolve, reject) => {
			axios.post(url, Data).then((res) => {
				resolve(res.data)
			}).catch((err) => {
				reject(err)
			})
		})
		return this.promise
	}
	/**
	 * PUT 请求
	 */
	put(url, Data) {
		this.isUserModel(url)
		this.promise = new Promise((resolve, reject) => {
			axios.put(url, Data).then((res) => {
				resolve(res.data)
			}).catch((err) => {
				reject(err)
			})
		})
		return this.promise
	}
	/**
	 * DELETE 请求
	 */
	delete(url, urlData, data = 0) {
		this.isUserModel(url)
		this.promise = new Promise((resolve, reject) => {
			if (data === 0) {
				for (const item in urlData) {
					url += '/' + urlData[item]
				}
			}
			axios.delete(url).then((res) => {
				resolve(res.data)
			}).catch((err) => {
				reject(err)
			})
		})
		return this.promise
	}
}
let ajax = new Axios()
export default ajax
