import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@/common/styles/index.less'
import fetch from '@/common/js/fetch'
import util from '@/common/js/util'

Vue.prototype.$ajax = fetch
Vue.prototype.$util = util

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
