let util = {
	getSession(key) {
		if (window.sessionStorage) {
			let item = sessionStorage.getItem(key)
			return JSON.parse(item)
		}
		return null
	},
	setSession(key, value) {
		if (window.sessionStorage) {
			sessionStorage.setItem(key, JSON.stringify(value))
		}
	}
}

export default util